import { authConfig } from "../../utils";
import { facebookSmall, googleSmall, lineSmall } from "../../assets/social";
import { Link } from "react-router-dom";

const facebookLoginUri = `https://${authConfig.domain}/oauth2/authorize?identity_provider=Facebook&response_type=code&client_id=${authConfig.clientId}&redirect_uri=${authConfig.redirectUri}&scope=email%20openid`;

const googleLoginUri = `https://${authConfig.domain}/oauth2/authorize?identity_provider=Google&response_type=code&client_id=${authConfig.clientId}&redirect_uri=${authConfig.redirectUri}&scope=email%20openid`;

const lineLoginUri = `https://${authConfig.domain}/oauth2/authorize?identity_provider=LINE%E8%AA%8D%E8%A8%BC&response_type=code&client_id=${authConfig.clientId}&redirect_uri=${authConfig.redirectUri}&scope=email%20openid`;

const handleGoogleLogin = () => {
  localStorage.clear();
  window.location.href = googleLoginUri;
};

const handleFacebookLogin = () => {
  localStorage.clear();
  window.location.href = facebookLoginUri;
};

const handleLineLogin = () => {
  localStorage.clear();
  window.location.href = lineLoginUri;
};

export default function LoginBody({ isOnLandingPage = false }) {
  return (
    <div
      className={` mx-auto p-5 flex flex-col items-center ${
        isOnLandingPage
          ? "lg:justify-center h-full"
          : "max-w-[450px] h-[calc(100svh-120px)] bg-white"
      }`}
    >
      <div />
      <div className="w-full">
        <p
          className={`${
            isOnLandingPage ? "text-white lg:text-black" : "text-black"
          } text-[18px] font-bold text-center my-5`}
        >
          会員登録
        </p>
        <button
          className="flex flex-row items-center rounded-[12px] w-[95%] mx-auto px-3 py-3 relative my-5 bg-white"
          style={{ border: "1px solid #D5D5D5" }}
          onClick={handleGoogleLogin}
        >
          <img src={googleSmall} className="w-6 h-6 absolute left-3" alt="" />
          <p className={`text-[14px] "text-black" font-bold mx-auto`}>
            Googleで登録
          </p>
        </button>
        <button
          className="flex flex-row items-center rounded-[12px] w-[95%] mx-auto px-3 py-3 relative my-5  bg-[#0866FF]"
          onClick={handleFacebookLogin}
        >
          <img src={facebookSmall} className="w-6 h-6 absolute left-3" alt="" />
          <p className="text-[14px] text-white font-bold mx-auto">
            Facebookで登録
          </p>
        </button>
        <button
          className="flex flex-row items-center rounded-[12px] w-[95%] mx-auto px-3 py-3 relative my-5 bg-[#03C300]"
          onClick={handleLineLogin}
        >
          <img src={lineSmall} className="w-6 h-6 absolute left-3" alt="" />
          <p className="text-[14px] text-white font-bold mx-auto">LINEで登録</p>
        </button>
      </div>
      <p
        className={`text-[12px] ${
          isOnLandingPage ? "text-white lg:text-black" : "text-black"
        } `}
      >
        <Link to={"/terms-and-conditions"}>
          <span
            className={`${
              isOnLandingPage
                ? "text-[#fff] lg:text-[#6658ea]"
                : "text-[#6658ea]"
            } font-extrabold text-[13px] lg:text-[12px]`}
          >
            利用規約
          </span>
        </Link>{" "}
        および
        <Link to="/privacy-policy">
          <span
            className={`${
              isOnLandingPage
                ? "text-[#fff] lg:text-[#6658ea]"
                : "text-[#6658ea]"
            } font-extrabold text-[13px] lg:text-[12px]`}
          >
            プライバシーポリシー
          </span>
        </Link>
        に同意の上、登録又はログインへお進みください。
      </p>
    </div>
  );
}
