export const WhiteCard = ({
  children,
  customClass = "",
  customStyleObj = {},
}) => {
  return (
    <div
      className={`bg-white rounded-2xl ${customClass}`}
      style={{
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        ...customStyleObj,
      }}
    >
      {children}
    </div>
  );
};
