export const authConfig = {
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_9chPTsUqH",
    clientId: "7gfvjtpu5iq4dtb70vagfcu34n",
    redirectUri: window.location.origin,
    domain: "auth-biz.be-native.life",

};

export const logoutApp = () => {
    localStorage.clear();
    window.location.href =
        `https://${authConfig.domain}/logout?client_id=${authConfig.clientId}&logout_uri=${authConfig.redirectUri}`;
}