import type1 from './type1.svg'
import type2 from './type2.svg'
import type3 from './type3.svg'
import type4 from './type4.svg'
import type5 from './type5.svg'
import type6 from './type6.svg'
import type7 from './type7.svg'
import type8 from './type8.svg'
import type9 from './type9.svg'
import type10 from './type10.svg'

const getCharaType = (person_type) => {
    switch (person_type) {
        case '無邪気なコミュニケーター':
            return type1;
        case '感性の鋭いアナリスト':
            return type2;
        case '好奇心旺盛なコンサルタント':
            return type3;
        case '独創的なクリエイター':
            return type4;
        case '人情に厚いカウンセラー':
            return type5;
        case '責任感の強いプロジェクトリーダー':
            return type6;
        case '強情でメンタルの強いビジネスオーナー':
            return type7;
        case '巻き込み力の強いアントレプレナー':
            return type8;
        case '緻密で真面目なマネージャー':
            return type9;
        case 'お人好しなプランナー':
            return type10
        default:
            return ""
    }
}

export { getCharaType, type3, type4, type1 }