import { useNavigate, useParams } from "react-router-dom";
import BizCard from "../../components/BizCard";
import { useCallback, useEffect, useState } from "react";
import { arrowBack, native } from "../../assets";

export default function NativeBizCard() {
  const navigate = useNavigate();
  const { name, year, month, date } = useParams();

  const [dataSample, setDataSample] = useState();
  const [isLoading, setIsLoading] = useState();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    let accessToken = null;
    const codeFromLocalStorage = localStorage.getItem("CODE");
    if (
      codeFromLocalStorage?.length > 0 &&
      codeFromLocalStorage !== "undefined"
    ) {
      const tokenResponse = await fetch(
        `https://api.be-native.life/api/cognito/biz/login?auth_code=${codeFromLocalStorage}`,
        {
          method: "POST",
        }
      );
      const tokenData = await tokenResponse.json();
      accessToken = tokenData?.access_token;
    }
    const apiResponse = await fetch(
      `https://api.be-native.life/api/biz/hr_card/${year}/${month}/${date}`,
      {
        headers: {
          ...(!!accessToken
            ? {
                Authorization: "Bearer " + accessToken,
              }
            : {}),
        },
      }
    );
    const data = await apiResponse.json();
    setDataSample(data);
    setIsLoading(false);
  }, [year, month, date]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="bg-[#9CADC8] w-full h-[100svh] flex flex-col items-center justify-center">
      <div className="hidden w-full xl:flex flex-row justify-between px-10 my-10 fixed top-[-10px]">
        <div className="flex flex-row items-end gap-2">
          <img src={native} className="w-[100px]" alt="" />
          <p className="text-[14px] text-white font-semibold relative top-1">
            for Biz
          </p>
        </div>
        <div
          className="flex flex-row items-center gap-2 cursor-pointer"
          onClick={() => navigate("/issue-card")}
        >
          <img src={arrowBack} className="w-[20px]" alt="" />
          <p className="text-[14px] text-white font-semibold relative">
            他の人を診断する
          </p>
        </div>
      </div>
      {isLoading ? (
        <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
          <p className="text-[24px] font-bold text-white">読み込み中...</p>
        </div>
      ) : (
        <div className="w-full xl:w-[90%] xl:h-[80%] max-w-[1280px] rounded-xl mx-auto overflow-auto">
          <BizCard
            name={name}
            year={year}
            month={month}
            date={date}
            dataSample={dataSample}
          />
        </div>
      )}
    </div>
  );
}
