import { useEffect, useState } from "react";
import { native, nativeBlack } from "../../assets";
import { WhiteCard } from "../../components/PageComponents";
import { useNavigate } from "react-router-dom";

export default function IssueCard() {
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [date, setDate] = useState({ date: "", month: "", year: "" });
  const [name, setName] = useState("");

  const isDataFilled =
    name?.length > 0 &&
    date?.year?.length > 0 &&
    date?.month?.length > 0 &&
    date?.date?.length > 0;

  useEffect(() => {
    let timer;
    const codeFromLocalStorage = localStorage.getItem("CODE");
    if (
      !!codeFromLocalStorage &&
      codeFromLocalStorage?.length > 0 &&
      codeFromLocalStorage !== "undefined"
    ) {
      timer = setTimeout(() => {
        setIsPageLoading(false);
      }, 500);
    } else {
      navigate("/login");
    }
    return () => clearTimeout(timer);
  }, [navigate]);

  return isPageLoading ? (
    <div className="h-[100svh] w-full flex-col flex items-center justify-center bg-white">
      <div className="flex flex-row items-end gap-2">
        <img src={nativeBlack} className="w-[100px]" alt="" />
        <p className="text-[16px] text-black font-semibold relative top-1">
          for Biz
        </p>
      </div>
    </div>
  ) : (
    <div className="bg-[#9CADC8] w-full h-[100svh] flex flex-col items-center justify-center">
      <div className="flex flex-row items-end gap-2">
        <img src={native} className="w-[100px]" alt="" />
        <p className="text-[14px] text-white font-semibold relative top-1">
          for Biz
        </p>
      </div>
      <WhiteCard customClass="mt-5 w-[95%] lg:w-[500px] p-10">
        <p className="text-[14px] text-black mb-10">
          20万の統計パターン、1万人を超える個人分析データを統合したnative.
          cardを用いて、面談前の1秒キャリア診断を実施。
          下記項目をご入力ください。
        </p>
        <div className="flex flex-row justify-between items-center w-full">
          <p className="text-black text-[14px] font-semibold">名前</p>
          <input
            className="w-[75%] lg:w-[70%] bg-[#F9F9F9] rounded-md py-1 px-2 text-[14px]"
            style={{ border: "1px solid #CCC" }}
            placeholder="例）川本ガンジ"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-between items-center w-full mt-5">
          <p className="text-black text-[14px] font-semibold">生年月日</p>
          <div className="w-[75%] lg:w-[70%] flex flex-row items-center gap-2 ">
            <input
              className="w-[60px] bg-[#F9F9F9] rounded-md py-1 px-2 text-[14px]"
              style={{ border: "1px solid #CCC" }}
              placeholder="yyyy"
              maxLength={4}
              value={date?.year}
              onChange={(e) => {
                if (isNaN(e.target.value)) return;
                setDate((p) => {
                  return { ...p, year: e.target.value };
                });
              }}
            />
            <p className="text-[#999] text-[14px]">年</p>
            <input
              className="w-[45px] bg-[#F9F9F9] rounded-md py-1 px-2 text-[14px]"
              style={{ border: "1px solid #CCC" }}
              placeholder="mm"
              maxLength={2}
              value={date?.month}
              onChange={(e) =>
                setDate((p) => {
                  if (!isNaN(e.target.value)) {
                    return { ...p, month: e.target.value };
                  } else {
                    return p;
                  }
                })
              }
            />
            <p className="text-[#999] text-[14px]">月</p>
            <input
              className="w-[45px] bg-[#F9F9F9] rounded-md py-1 px-2 text-[14px]"
              style={{ border: "1px solid #CCC" }}
              placeholder="dd"
              maxLength={2}
              value={date?.date}
              onChange={(e) =>
                setDate((p) => {
                  if (!isNaN(e.target.value)) {
                    return { ...p, date: e.target.value };
                  } else {
                    return p;
                  }
                })
              }
            />
            <p className="text-[#999] text-[14px]">日</p>
          </div>
        </div>
        <button
          className="w-full p-2 flex flex-col items-center justify-center rounded-lg text-white font-semibold mt-5"
          style={{
            background: "linear-gradient(90deg, #B69AC5 0%, #7FC4CA 100%)",
            opacity: isDataFilled ? 1 : 0.5,
            boxShadow: isDataFilled
              ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
              : "none",
          }}
          disabled={!isDataFilled}
          onClick={() =>
            navigate(
              `/native-biz-card/${name?.trim()}/${date?.year?.trim()}/${date?.month?.trim()}/${date?.date?.trim()}`
            )
          }
        >
          診断する
        </button>
      </WhiteCard>
    </div>
  );
}
