export const getIromaruColour = (iromaru) => {
    switch (iromaru) {
        case "成長心強いリーダー":
            return '#78EBB4';
        case "温厚なコネクター":
            return '#F4C2CD';
        case "情熱的なモチベーター":
            return '#FFBF93';
        case "感性鋭いクリエーター":
            return '#F7E740';
        case "包容力高いカリスマ":
            return '#89BE6E';
        case "柔和なプロデューサー":
            return '#D6B08A';
        case "頑固な神メンタル":
            return '#C7C7C7';
        case "繊細な完璧主義者":
            return '#C29DCC';
        case "自由奔放なオーナー":
            return '#99C5F4';
        case "癒し系なサポーター":
            return '#B1DFF1'
        default:
            return ""
    }
}

export const getSyugoshinColor = (syugoshin) => {
    switch (syugoshin) {
        case "感性鋭いクリエーター":
            return "#F7E740";
        case "成長心強いリーダー":
            return "#78EBB4";
        case "温厚なコネクター":
            return "#F4C2CD";
        case "情熱的なモチベーター":
            return "#FFBF93";
        case "包容力高いカリスマ":
            return "#89BE6E";
        case "柔和なプロデューサー":
            return "#D6B08A";
        case "頑固な神メンタル":
            return "#C7C7C7";
        case "繊細な完璧主義者":
            return "#C29DCC";
        case "自由奔放なオーナー":
            return "#99C5F4";
        case "癒し系なサポーター":
            return "#B1DFF1";
        default:
            return ""
    }
}

export const getFormattedIromaruSentence = (iromarySentence) => {
    let line1 = ''
    let line2 = ''
    switch (iromarySentence) {
        case "成長心強いリーダー":
            line1 = '成長心強い'
            line2 = 'リーダー'
            break;

        case "温厚なコネクター":
            line1 = '温厚な'
            line2 = 'コネクター'
            break;

        case "情熱的なモチベーター":
            line1 = '情熱的な'
            line2 = 'モチベーター'
            break;

        case "感性鋭いクリエーター":
            line1 = '感性鋭い'
            line2 = 'クリエーター'
            break;

        case "包容力高いカリスマ":
            line1 = '包容力高い'
            line2 = 'カリスマ'
            break;

        case "柔和なプロデューサー":
            line1 = '柔和な'
            line2 = 'プロデューサー'
            break;

        case "頑固な神メンタル":
            line1 = '頑固な'
            line2 = '神メンタル'
            break;

        case "繊細な完璧主義者":
            line1 = '繊細な'
            line2 = '完璧主義者'
            break;


        case "自由奔放なオーナー":
            line1 = '自由奔放な'
            line2 = 'オーナー'
            break;

        case "癒し系なサポーター":
            line1 = '癒し系な'
            line2 = 'サポーター'
            break;
    }

    return { line1, line2 }
}

export const getFormattedSyugoshinSentence = (syugoshinSentence) => {
    let line1 = ''
    let line2 = ''
    switch (syugoshinSentence) {
        case "感性鋭いクリエーター":
            line1 = '感性鋭い'
            line2 = 'クリエイター'
            break;

        case "成長心強いリーダー":
            line1 = '成長心強い'
            line2 = 'リーダー'
            break;

        case "温厚なコネクター":
            line1 = '濃厚な'
            line2 = 'コネクター'
            break;

        case "情熱的なモチベーター":
            line1 = '情熱的な'
            line2 = 'モチベーター'
            break;

        case "包容力高いカリスマ":
            line1 = '包容力高い'
            line2 = 'カリスマ'
            break;

        case "柔和なプロデューサー":
            line1 = '柔和な'
            line2 = 'プロデューサー'
            break;

        case "頑固な神メンタル":
            line1 = '頑固な'
            line2 = '神メンタル'
            break;

        case "繊細な完璧主義者":
            line1 = '繊細な'
            line2 = '完璧主義者'
            break;

        case "自由奔放なオーナー":
            line1 = '自由奔放な'
            line2 = 'オーナー'
            break;

        case "癒し系なサポーター":
            line1 = '癒し系な'
            line2 = 'サポーター'
            break;
    }

    return { line1, line2 }
}