import { useNavigate, useSearchParams } from "react-router-dom";
import {
  basicAttributesPreview,
  landingSec1Bg,
  lastSectionBg,
  nativeBlack,
  sec1img1,
  sec1img2,
  sec1img3,
  sectionPreview,
  tagsPreview,
  yokubouPreview,
} from "../../assets";
import { useCallback, useEffect, useState } from "react";
import { type1, type3, type4 } from "../../assets/personType";
import LoginBody from "../login/LoginBody";

export default function LandingPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isPageLoading, setIsPageLoading] = useState(true);

  const setCode = useCallback(async () => {
    localStorage.setItem("CODE", searchParams.get("code"));
    navigate("/issue-card");
  }, [navigate, searchParams]);

  useEffect(() => {
    let timer;
    if (searchParams.get("code")?.length > 0) {
      setCode();
    } else {
      timer = setTimeout(() => {
        setIsPageLoading(false);
      }, 500);
    }
    return () => {
      setIsPageLoading(true);
      clearTimeout(timer);
    };
  }, [searchParams, setCode]);

  const handleRegister = () => {
    navigate("/login");
  };

  return isPageLoading ? (
    <div className="h-[100svh] w-full flex-col flex items-center justify-center bg-white">
      <div className="flex flex-row items-end gap-2">
        <img src={nativeBlack} className="w-[100px]" alt="" />
        <p className="text-[16px] text-black font-semibold relative top-1">
          for Biz
        </p>
      </div>
    </div>
  ) : (
    <div className="w-full bg-white min-h-[100svh]">
      <div
        className="flex flex-row items-center justify-center p-6"
        style={{
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          borderBottom: "3px solid rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="flex flex-row items-end gap-2">
          <img src={nativeBlack} className="w-[100px]" alt="" />
          <p className="text-[16px] text-black font-semibold relative top-1">
            for Biz
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center gap-6 p-5 h-[500px] bg-white">
        <h1 className="font-bold text-black text-[28px] lg:text-[48px] mt-[-16px] text-center">
          １秒キャリア診断サービス
        </h1>
        <p className="font-bold text-black text-[24px] lg:text-[42px] text-center">
          native. for biz
        </p>
        <p className="text-[12px] xl:text-[16px]  text-center">
          生年月日入力のみ。生年月日をベースとした20万以上の統計パターン、
          <br />
          1万人を超える個人分析経験データを統合してキャリア嗜好性を出力
        </p>
        <button
          onClick={handleRegister}
          className="w-ma px-16 py-2 flex flex-col items-center justify-center rounded-lg text-white font-semibold mt-5"
          style={{
            background: "linear-gradient(90deg, #B69AC5 0%, #7FC4CA 100%)",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          今すぐ会員登録、無料診断
        </button>
      </div>
      <div
        className="flex flex-col gap-10 items-center justify-center h-[1200px] lg:h-[550px] p-5"
        style={{
          background: `URL(${landingSec1Bg})`,
          backgroundSize: "cover",
          backgroundPosition: "55% 50%",
        }}
      >
        <p className="hidden lg:block text-center text-white text-[24px] font-semibold">
          60分の時間密度を高め、一度の面接で一生の繋がりを演出
        </p>
        <p className="lg:hidden text-center text-white text-[20px] font-semibold">
          60分の時間密度を高め、
          <br />
          一度の面接で一生の繋がりを演出
        </p>
        <div className="flex flex-col lg:grid lg:grid-cols-3 gap-10">
          <div className="flex flex-col items-center gap-5">
            <p className="text-center text-white text-[20px] font-bold">
              採用面談
            </p>
            <img
              src={sec1img1}
              className="w-[250px] object-contain rounded-2xl"
              alt=""
            />
            <p className="text-[14px] text-white text-center font-semibold">
              経営者、採用人事が、求職者との面談の質向上の
              <br />
              ために履歴書、職歴書に3つ目のピースを加える
            </p>
          </div>

          <div className="flex flex-col items-center gap-5">
            <p className="text-center text-white text-[20px] font-bold">
              キャリアアドバイザー
            </p>
            <img
              src={sec1img2}
              className="w-[250px] object-contain rounded-2xl"
              alt=""
            />
            <p className="text-[14px] text-white text-center font-semibold">
              キャリア面談時に、相手の理解を深めるための
              <br />
              問いの設計に役立てる
            </p>
          </div>

          <div className="flex flex-col items-center gap-5">
            <p className="text-center text-white text-[20px] font-bold">
              コーチング
            </p>
            <img
              src={sec1img3}
              className="w-[250px] object-contain rounded-2xl"
              alt=""
            />
            <p className="text-[14px] text-white text-center font-semibold">
              コーチングを受ける方の欲望と思考傾向を
              <br />
              把握し、相手の理解度向上に役立てる
            </p>
          </div>
        </div>
        <button
          onClick={handleRegister}
          className="w-ma px-16 py-2 flex flex-col items-center justify-center rounded-lg text-white font-semibold mt-5"
          style={{
            background: "linear-gradient(90deg, #B69AC5 0%, #7FC4CA 100%)",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          今すぐ会員登録、無料診断
        </button>
      </div>

      <div
        className="h-[900px] lg:h-[500px] flex flex-col lg:flex-row items-center justify-center gap-5 lg:gap-20"
        style={{
          background:
            " linear-gradient(0deg, #D3E6EA 0%, #D3E6EA 100%), url(<path-to-image>) lightgray 0px -234.947px / 100% 132.545% no-repeat",
        }}
      >
        <div className="relative w-[320px] lg:w-[380px] h-[400px]">
          <div
            className="p-5 w-[200px] rounded-2xl bg-white absolute right-7 z-0"
            style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }}
          >
            <img src={type3} className="w-[180px] rounded-xl" alt="" />
          </div>
          <div
            className="p-5 w-[200px] rounded-2xl bg-white z-20 absolute left-0 top-28"
            style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }}
          >
            <img src={type4} className="w-[180px] rounded-xl" alt="" />
          </div>
          <div
            className="p-5 w-[200px] rounded-2xl bg-white z-30 absolute right-0 bottom-0"
            style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }}
          >
            <img src={type1} className="w-[180px] rounded-xl" alt="" />
          </div>
        </div>
        <div className="flex flex-col gap-10  w-full lg:w-[400px] p-5">
          <p className="text-[24px] text-black font-semibold text-center">
            診断結果は1,000パターン
          </p>
          <p className="text-[16px] text-black text-center">
            10種類の属性と理想のカルチャー、チームメンバー、業界/職種等、豊富なカテゴリーの診断結果をご用意。
            <br />
            一人一人にフィットしたコミュニケーション、問いをデザインし、ご面談者の心を掴みましょう。
          </p>
          <button
            onClick={handleRegister}
            className="w-ma px-16 py-2 flex flex-col items-center justify-center rounded-lg text-white font-semibold mt-5"
            style={{
              background: "linear-gradient(90deg, #B69AC5 0%, #7FC4CA 100%)",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          >
            今すぐ会員登録、無料診断
          </button>
        </div>
      </div>

      <div
        className="h-[850px] lg:h-[500px] flex flex-col-reverse lg:flex-row items-center justify-center gap-5 lg:gap-20"
        style={{
          background:
            "linear-gradient(0deg, #E1DBE9 0%, #E1DBE9 100%), url(<path-to-image>) lightgray 0px -234.947px / 100% 132.545% no-repeat",
        }}
      >
        <div className="flex flex-col gap-10 w-full lg:w-[400px] p-5">
          <p className="text-[24px] text-black font-semibold text-center">
            チーム内の適切な役割、
            <br />
            欲望が丸わかり
          </p>
          <p className="text-[16px] text-black text-center">
            native.スコアという独自スコアリングを用いて、起業家、マネージャー、リサーチャー等、組織における適職傾向に加え、人間が基本的に持つ5つの欲望から、何が人生、キャリア選択に重要か、優先順位傾向が分かる。
          </p>
          <button
            onClick={handleRegister}
            className="w-ma px-16 py-2 flex flex-col items-center justify-center rounded-lg text-white font-semibold mt-5"
            style={{
              background: "linear-gradient(90deg, #B69AC5 0%, #7FC4CA 100%)",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            }}
          >
            今すぐ会員登録、無料診断
          </button>
        </div>
        <div className="relative w-[320px] lg:w-[400px] flex flex-col gap-5">
          <div className="flex flex-row gap-5 ">
            <img src={basicAttributesPreview} className="w-[40%]" alt="" />
            <img src={yokubouPreview} className="w-[60%]" alt="" />
          </div>
          <div className="flex flex-row gap-5 ">
            <img src={tagsPreview} className="w-[60%]" alt="" />
            <img src={sectionPreview} className="w-[40%]" alt="" />
          </div>
          <div className="flex flex-row gap-5 "></div>
        </div>
      </div>
      <div
        className="h-[600px] lg:h-[600px] w-full flex flex-col lg:flex-row items-center justify-center gap-10 lg:gap-20"
        style={{ background: `URL(${lastSectionBg})`, backgroundSize: "cover" }}
      >
        <p className="text-[36px] lg:text-[56px] text-white font-bold mt-10 mb-[-50px] lg:mb-0 lg:mt-0">
          5秒で簡単登録
          <br /> 1秒でキャリア探索
        </p>
        <div className="bg-transparent lg:bg-white w-full lg:w-[450px] h-full">
          <LoginBody isOnLandingPage />
        </div>
      </div>
    </div>
  );
}
