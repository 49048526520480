import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./global.css";
import NativeBizCard from "./pages/NativeBizCard";
import IssueCard from "./pages/IssueCard";
import LandingPage from "./pages/landing";
import Login from "./pages/login";
import TermsAndConditions from './pages/terms'
import PrivacyPolicy from './pages/privacy'

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: 'native-biz-card/:name/:year/:month/:date',
    element: <NativeBizCard />
  },
  {
    path: 'issue-card',
    element: <IssueCard />
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '*',
    element: <LandingPage />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);