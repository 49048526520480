import { WhiteCard } from "../PageComponents";
import {
  bulbIcon,
  questionMark,
  chishiki,
  hyougen,
  koudou,
  jigyaku,
  okane,
  thinking,
  legal,
  target,
  triangle,
  building,
  suitcase,
  globe,
  team,
  pageBg,
  basicAttributesInfo,
  yokubouInfo,
} from "../../assets";
import { getCharaType } from "../../assets/personType";
import {
  getFormattedIromaruSentence,
  getFormattedSyugoshinSentence,
  getIromaruColour,
  getSyugoshinColor,
} from "./utils";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useState } from "react";

export default function BizCard({ name, year, month, date, dataSample }) {
  const age = Math.floor(
    (new Date() - new Date(`${year}-${month}-${date}`).getTime()) / 3.15576e10
  );
  const currentDate = new Date();
  const [openBasicAttributesModal, setOpenBasicAttributesModal] =
    useState(false);
  const [openYokubouModal, setOpenYokubouModal] = useState(false);
  return (
    <>
      <Modal
        open={openBasicAttributesModal}
        onClose={() => {
          setOpenBasicAttributesModal(false);
        }}
        center
      >
        <img
          src={basicAttributesInfo}
          className="w-[800px] object-contain"
          alt=""
        />
      </Modal>
      <Modal
        open={openYokubouModal}
        onClose={() => {
          setOpenYokubouModal(false);
        }}
        center
      >
        <img src={yokubouInfo} className="w-[500px] object-contain" alt="" />
      </Modal>
      <div className="pb-10">
        {/* Header Section Start */}
        <div
          className="w-full flex flex-col xl:flex-row items-center justify-between h-[140px] xl:h-[80px] px-8 py-5 bg-white"
          style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div className="flex flex-col xl:flex-row items-center gap-3">
            <div className="flex flex-row items-center gap-3">
              <p className="text-[18px] xl:text-[24px] font-bold">{name}</p>
              <p className="text-[16px] xl:text-[20px]">さんの診断レポート</p>
            </div>
            <div
              className="flex flex-row items-center font-semibold px-3 py-1"
              style={{ border: "1px solid #CCC" }}
            >
              <div className="w-full">
                {year} 年 {month} 月 {date} 日
              </div>
              <div
                className="w-20 pl-3"
                style={{ borderLeft: "1px solid #ccc" }}
              >
                {age}歳
              </div>
            </div>
          </div>
          <p className="text-[#999] text-[12px]">
            診断日 {currentDate?.getFullYear()} 年 {currentDate?.getMonth() + 1}{" "}
            月 {currentDate?.getDate()} 日
          </p>
        </div>
        {/* Header Section End */}
        <div
          className="w-full pb-10"
          style={{ background: `URL(${pageBg}), #eee` }}
        >
          <div className="w-[90%] xl:max-w-[1200px] mx-auto">
            {/* Section One Start */}
            <div className="pt-10">
              <WhiteCard>
                <div className="p-6 xl:p-12">
                  <div className="flex flex-col-reverse xl:flex-row gap-0 xl:gap-10 items-center">
                    <div className="w-full">
                      <div className="bg-[#9CADC8] p-3 rounded-lg w-full">
                        <p className="text-center font-bold text-white text-[16px] xl:text-[24px]">
                          {dataSample?.person_type}
                        </p>
                      </div>
                      <div className="bg-[#9CADC8] p-3 rounded-lg w-full my-5 flex flex-row items-center justify-center gap-3">
                        <img src={bulbIcon} className="w-6 h-6" alt="" />
                        <p className="text-center font-bold text-white text-[16px] xl:text-[24px]">
                          {name} さんと面談する上でのポイント
                        </p>
                      </div>
                      <div className="flex flex-col xl:flex-row gap-5 items-center">
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {dataSample?.advice_tag1?.split("#")?.[1]}
                          </div>
                        </div>
                        <p className="text-[14px] ">{dataSample?.advice_1}</p>
                      </div>
                      <div
                        className="h-0 w-full my-5"
                        style={{ border: "1px solid #999" }}
                      />
                      <div className="flex flex-col xl:flex-row gap-5 items-center">
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {dataSample?.advice_tag2?.split("#")?.[1]}
                          </div>
                        </div>
                        <p className="text-[14px]">{dataSample?.advice_2}</p>
                      </div>
                    </div>
                    <img
                      alt=""
                      src={getCharaType(dataSample?.person_type)}
                      className=" w-[90%] max-w-[280px] xl:w-[250px] object-contain"
                    />
                  </div>
                </div>
              </WhiteCard>
            </div>
            {/* Section One End */}

            {/* Section Two Start */}
            <div className="flex flex-col xl:flex-row justify-between w-full mt-10 gap-5">
              {/* Basic Attributes Start */}
              <div className="flex flex-col items-center gap-2">
                <div className="flex flex-row items-center gap-1">
                  <p className="text-[18px]">基本属性</p>
                  <img
                    src={questionMark}
                    className="h-5 w-5 cursor-pointer"
                    alt=""
                    onClick={() => setOpenBasicAttributesModal(true)}
                  />
                </div>
                <WhiteCard customClass="h-full w-full">
                  <div className="w-full xl:w-[280px] py-2 px-5">
                    <div className="flex flex-row items-center justify-center xl:justify-between gap-5">
                      <div className="flex flex-col items-center justify-center w-[110px] h-[110px] gap-1 rounded-full bg-[#222]">
                        <p className="text-[12px] text-white font-semibold">
                          native.
                          <br /> スコア
                        </p>
                        <p className="text-[46px] text-white font-bold mt-[-16px]">
                          {dataSample?.native_score}
                        </p>
                      </div>
                      <div
                        className="flex flex-col items-center justify-center w-[110px] h-[110px] gap-1 rounded-full p-3"
                        style={{
                          backgroundColor: getIromaruColour(
                            dataSample?.iromaru_sentence
                          ),
                        }}
                      >
                        <p className="text-[17px] text-white font-semibold text-wrap text-center">
                          {
                            getFormattedIromaruSentence(
                              dataSample?.iromaru_sentence
                            )?.line1
                          }
                        </p>
                        <p className="text-[15px] text-white font-semibold text-wrap text-center">
                          {
                            getFormattedIromaruSentence(
                              dataSample?.iromaru_sentence
                            )?.line2
                          }
                        </p>
                      </div>
                    </div>
                    <div
                      className="h-0 w-full my-3"
                      style={{ border: "1px dashed #999" }}
                    />
                    <p className="text-[14px] text-[#222] mb-3 text-center">
                      相性の良いビジネスパートナー
                    </p>
                    <div className="flex flex-row items-center gap-3 justify-center xl:justify-between">
                      <div className="flex flex-row">
                        <div className="w-6 h-6 rounded-full flex flex-col items-center justify-center p-1 text-[16px] mr-1 font-semibold">
                          No.1
                        </div>
                        <div
                          className="w-[90px] h-[90px] rounded-full p-2 flex flex-col items-center justify-center"
                          style={{
                            border: `4px solid ${getSyugoshinColor(
                              dataSample?.syugoshin_1
                            )}`,
                          }}
                        >
                          <p
                            className="text-[12.5px] text-center font-semibold"
                            style={{
                              color: getSyugoshinColor(dataSample?.syugoshin_1),
                            }}
                          >
                            {
                              getFormattedSyugoshinSentence(
                                dataSample?.syugoshin_1
                              )?.line1
                            }
                          </p>
                          <p
                            className="text-[11px] text-center font-semibold"
                            style={{
                              color: getSyugoshinColor(dataSample?.syugoshin_1),
                            }}
                          >
                            {
                              getFormattedSyugoshinSentence(
                                dataSample?.syugoshin_1
                              )?.line2
                            }
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row">
                        <div className="w-6 h-6 rounded-full flex flex-col items-center justify-center  p-1 text-[16px] mr-1 font-semibold">
                          No.2
                        </div>
                        <div
                          className="w-[90px] h-[90px] rounded-full p-2 flex flex-col items-center justify-center"
                          style={{
                            border: `4px solid ${getSyugoshinColor(
                              dataSample?.syugoshin_2
                            )}`,
                          }}
                        >
                          <p
                            className="text-[12.5px] text-center font-semibold"
                            style={{
                              color: getSyugoshinColor(dataSample?.syugoshin_2),
                            }}
                          >
                            {
                              getFormattedSyugoshinSentence(
                                dataSample?.syugoshin_2
                              )?.line1
                            }
                          </p>
                          <p
                            className="text-[11px] text-center font-semibold"
                            style={{
                              color: getSyugoshinColor(dataSample?.syugoshin_2),
                            }}
                          >
                            {
                              getFormattedSyugoshinSentence(
                                dataSample?.syugoshin_2
                              )?.line2
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </WhiteCard>
              </div>
              {/* Basic Attributes End */}

              {/* Yokubou Params Start */}
              <div className="flex flex-col items-center gap-2">
                <div className="flex flex-row items-center gap-1">
                  <p className="text-[18px]">欲望傾向</p>
                  <img
                    src={questionMark}
                    className="h-5 w-5 cursor-pointer"
                    alt=""
                    onClick={() => setOpenYokubouModal(true)}
                  />
                </div>
                <WhiteCard customClass="h-full w-full">
                  <div className=" w-full xl:w-[360px] p-5 flex flex-col gap-2">
                    <div className="flex flex-row items-center justify-between">
                      <div className="flex flex-row gap-2 items-center min-w-[120px]">
                        <img src={okane} className="w-6 h-6" alt="" />
                        <p className="text-[20px]">金欲</p>
                      </div>
                      <p className="text-[28px] font-semibold">
                        {dataSample?.yokubou_params?.okane?.yokubou_sum}%
                      </p>
                      <div className="flex items-center justify-end flex-row gap-2 w-[200px]">
                        <p className="text-[12px]">
                          (散財{" "}
                          {dataSample?.yokubou_params?.okane?.yokubou_score1}% /
                          蓄財{" "}
                          {dataSample?.yokubou_params?.okane?.yokubou_score2}
                          %)
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row items-center justify-between">
                      <div className="flex flex-row gap-2 items-center min-w-[120px]">
                        <img src={hyougen} className="w-6 h-6" alt="" />
                        <p className="text-[20px]">表現欲</p>
                      </div>
                      <p className="text-[28px] font-semibold">
                        {dataSample?.yokubou_params?.hyougen?.yokubou_sum}%
                      </p>
                      <div className="flex items-center flex-row gap-2 w-[200px] justify-end">
                        <p className="text-[12px]">
                          (派手{" "}
                          {dataSample?.yokubou_params?.hyougen?.yokubou_score1}%
                          / 地味{" "}
                          {dataSample?.yokubou_params?.hyougen?.yokubou_score2}
                          %)
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row items-center justify-between">
                      <div className="flex flex-row gap-2 items-center min-w-[120px]">
                        <img src={chishiki} className="w-6 h-6" alt="" />
                        <p className="text-[20px]">知識欲</p>
                      </div>
                      <p className="text-[28px] font-semibold">
                        {dataSample?.yokubou_params?.chishiki?.yokubou_sum}%
                      </p>
                      <div className="flex items-center flex-row gap-2 w-[200px] justify-end">
                        <p className="text-[12px]">
                          (王道{" "}
                          {dataSample?.yokubou_params?.chishiki?.yokubou_score1}
                          % / 雑学{" "}
                          {dataSample?.yokubou_params?.chishiki?.yokubou_score2}
                          %)
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row items-center justify-between">
                      <div className="flex flex-row gap-2 items-center min-w-[120px]">
                        <img src={jigyaku} className="w-6 h-6" alt="" />
                        <p className="text-[20px]">自我欲</p>
                      </div>
                      <p className="text-[28px] font-semibold">
                        {dataSample?.yokubou_params?.jigayoku?.yokubou_sum}%
                      </p>
                      <div className="flex items-center flex-row gap-2 w-[200px] justify-end">
                        <p className="text-[12px]">
                          (個人{" "}
                          {dataSample?.yokubou_params?.jigayoku?.yokubou_score1}
                          % / 組織{" "}
                          {dataSample?.yokubou_params?.jigayoku?.yokubou_score2}
                          %)
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row items-center justify-between">
                      <div className="flex flex-row gap-2 items-center min-w-[120px]">
                        <img src={koudou} className="w-6 h-6" alt="" />
                        <p className="text-[20px]">行動欲</p>
                      </div>
                      <p className="text-[28px] font-semibold">
                        {dataSample?.yokubou_params?.koudou?.yokubou_sum}%
                      </p>
                      <div className="flex items-center flex-row gap-2 w-[200px] justify-end">
                        <p className="text-[12px]">
                          (保守{" "}
                          {dataSample?.yokubou_params?.koudou?.yokubou_score1}%
                          / 大胆{" "}
                          {dataSample?.yokubou_params?.koudou?.yokubou_score2}%)
                        </p>
                      </div>
                    </div>
                  </div>
                </WhiteCard>
              </div>
              {/* Yokubou Params End */}

              {/* Ideal Career Start */}
              <div className="flex flex-col items-center gap-2 w-full">
                <div className="flex flex-row items-center gap-1">
                  <p className="text-[18px]">理想のキャリア</p>
                </div>
                <WhiteCard customClass="w-full h-full">
                  <div className="w-full p-5 grid grid-cols-2 gap-2">
                    <div className="bg-[#fafafa] w-full h-full flex flex-col items-center p-3 rounded-lg">
                      <div className="bg-[#9CADC8] w-[90%] py-1 px-1 flex flex-col items-center justify-center rounded-lg mb-1">
                        <p className="text-[14px] font-bold text-white text-center">
                          オススメ業界
                        </p>
                      </div>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.gyokai_tag1?.split("#")?.[1]}
                      </p>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.gyokai_tag2?.split("#")?.[1]}
                      </p>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.gyokai_tag3?.split("#")?.[1]}
                      </p>
                    </div>
                    <div className="bg-[#fafafa] w-full h-full flex flex-col items-center p-3 rounded-lg">
                      <div className="bg-[#9CADC8] w-[90%] py-1 px-1 flex flex-col items-center justify-center rounded-lg mb-1">
                        <p className="text-[14px] font-bold text-white text-center">
                          オススメ職種
                        </p>
                      </div>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.gyosyu_tag1?.split("#")?.[1]}
                      </p>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.gyosyu_tag2?.split("#")?.[1]}
                      </p>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.gyosyu_tag3?.split("#")?.[1]}
                      </p>
                    </div>
                    <div className="bg-[#fafafa] w-full h-full flex flex-col items-center p-3 rounded-lg">
                      <div className="bg-[#9CADC8] w-[90%] py-1 px-1 flex flex-col items-center justify-center rounded-lg mb-1">
                        <p className="text-[14px] font-bold text-white text-center">
                          理想のカルチャー
                        </p>
                      </div>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.culture_tag1?.split("#")?.[1]}
                      </p>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.culture_tag2?.split("#")?.[1]}
                      </p>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.culture_tag3?.split("#")?.[1]}
                      </p>
                    </div>
                    <div className="bg-[#fafafa] w-full h-full flex flex-col items-center p-3 rounded-lg">
                      <div className="bg-[#9CADC8] w-[90%] py-1 px-1 flex flex-col items-center justify-center rounded-lg mb-1">
                        <p className="text-[14px] font-bold text-white text-center">
                          理想のチームメンバー
                        </p>
                      </div>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.team_member_tag1?.split("#")?.[1]}
                      </p>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.team_member_tag2?.split("#")?.[1]}
                      </p>
                      <p className="text-[13px] my-[1px] text-center font-semibold">
                        #{dataSample?.team_member_tag3?.split("#")?.[1]}
                      </p>
                    </div>
                  </div>
                </WhiteCard>
              </div>
              {/* Ideal Career End */}
            </div>
            {/* Section Two End */}

            {/* Personality and Talent Start */}
            <div className="flex flex-row justify-center gap-3 mt-16 mb-10">
              <div
                className="h-0 w-[80px] mt-5"
                style={{ border: "1px solid #222" }}
              />
              <div className="flex flex-col items-center">
                <p className="text-[24px] font-bold">性格と才能</p>
                <p className="text-[14px] font-semibold">
                  Personality & Talent
                </p>
              </div>
              <div
                className="h-0 w-[80px] mt-5"
                style={{ border: "1px solid #222" }}
              />
            </div>

            <div className="flex flex-row justify-center gap-2 my-5">
              <img src={thinking} className="w-7" alt="" />
              <p className="text-[19px] font-semibold">パーソナリティ</p>
            </div>

            <div className="flex flex-col xl:grid xl:grid-cols-3 items-center gap-5">
              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.personality_tag1?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>

                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.personality_1}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.personality_tag2?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.personality_2}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.personality_tag3?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.personality_3}</p>
                </div>
              </WhiteCard>
            </div>

            <div className="flex flex-row justify-center gap-2 mt-14 mb-5">
              <img src={legal} className="w-7" alt="" />
              <p className="text-[19px] font-semibold">長所と短所</p>
            </div>
            <div className="flex flex-col xl:grid xl:grid-cols-2 gap-10">
              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center p-10">
                  <div className="flex flex-row gap-2 items-center justify-center">
                    <img src={target} className="w-8" alt="" />
                    <p className="text-[24px] font-semibold">長所</p>
                  </div>
                  <div className="my-3 flex flex-col items-center gap-3">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.tyosho_tag1?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0 my-3"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.tyosho_1}</p>
                  </div>

                  <div className="my-3 flex flex-col items-center gap-3">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.tyosho_tag2?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0 my-3"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.tyosho_2}</p>
                  </div>

                  <div className="my-3 flex flex-col items-center gap-3">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.tyosho_tag3?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0 my-3"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.tyosho_3}</p>
                  </div>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center p-10">
                  <div className="flex flex-row gap-2 items-center justify-center">
                    <img src={triangle} className="w-8" alt="" />
                    <p className="text-[24px] font-semibold">短所</p>
                  </div>
                  <div className="my-3 flex flex-col items-center gap-3">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.tansho_tag1?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0 my-3"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.tansho_1}</p>
                  </div>

                  <div className="my-3 flex flex-col items-center gap-3">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.tansho_tag2?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0 my-3"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.tansho_2}</p>
                  </div>

                  <div className="my-3 flex flex-col items-center gap-3">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.tansho_tag3?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0 my-3"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.tansho_3}</p>
                  </div>
                </div>
              </WhiteCard>
            </div>
            {/* Personality and Talent End */}

            {/* Industry Start */}
            <div className="flex flex-row justify-center gap-3 mt-20 mb-10">
              <div
                className="h-0 w-[80px] mt-5"
                style={{ border: "1px solid #222" }}
              />
              <div className="flex flex-col items-center">
                <p className="text-[24px] font-bold">業種</p>
                <p className="text-[14px] font-semibold">Job Type</p>
              </div>
              <div
                className="h-0 w-[80px] mt-5"
                style={{ border: "1px solid #222" }}
              />
            </div>

            <div className="flex flex-row justify-center gap-2 my-5">
              <img src={building} className="w-7" alt="" />
              <p className="text-[19px] font-semibold">フィットする業界</p>
            </div>

            <div className="flex flex-col xl:grid xl:grid-cols-3 items-center gap-5">
              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-col items-center gap-5 justify-center">
                    {dataSample?.gyokai_tag1?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.gyokai_1}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-col items-center gap-5 justify-center">
                    {dataSample?.gyokai_tag2?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.gyokai_2}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-col items-center gap-5 justify-center">
                    {dataSample?.gyokai_tag3?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.gyokai_3}</p>
                </div>
              </WhiteCard>
            </div>

            <div className="flex flex-row justify-center gap-2 mt-14 mb-5">
              <img src={suitcase} className="w-7" alt="" />
              <p className="text-[19px] font-semibold">フィットする職種</p>
            </div>

            <div className="flex flex-col xl:grid xl:grid-cols-3 items-center gap-5">
              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-col items-center gap-5 justify-center">
                    {dataSample?.gyosyu_tag1?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.gyosyu_1}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-col items-center gap-5 justify-center">
                    {dataSample?.gyosyu_tag2?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.gyosyu_2}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-col items-center gap-5 justify-center">
                    {dataSample?.gyosyu_tag3?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.gyosyu_3}</p>
                </div>
              </WhiteCard>
            </div>
            {/* Industry End */}

            {/* Environment Start */}
            <div className="flex flex-row justify-center gap-3 mt-20 mb-10">
              <div
                className="h-0 w-[80px] mt-5"
                style={{ border: "1px solid #222" }}
              />
              <div className="flex flex-col items-center">
                <p className="text-[24px] font-bold">環境</p>
                <p className="text-[14px] font-semibold">Environment</p>
              </div>
              <div
                className="h-0 w-[80px] mt-5"
                style={{ border: "1px solid #222" }}
              />
            </div>

            <div className="flex flex-row justify-center gap-2 my-5">
              <img src={globe} className="w-7" alt="" />
              <p className="text-[19px] font-semibold">理想の組織カルチャー</p>
            </div>

            <div className="flex flex-col xl:grid xl:grid-cols-3 items-center gap-5">
              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.culture_tag1?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.culture_1}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.culture_tag2?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.culture_2}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.culture_tag3?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.culture_3}</p>
                </div>
              </WhiteCard>
            </div>

            <div className="flex flex-row justify-center gap-2 mt-14 my-5">
              <img src={team} className="w-7" alt="" />
              <p className="text-[19px] font-semibold">理想のチームメンバー</p>
            </div>

            <div className="flex flex-col xl:grid xl:grid-cols-2 items-center gap-5">
              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.team_member_tag1?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.team_member_1}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.team_member_tag2?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.team_member_2}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.team_member_tag3?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.team_member_3}</p>
                </div>
              </WhiteCard>

              <WhiteCard customClass="w-full h-full">
                <div className="flex flex-col items-center gap-5 p-5">
                  <div className="flex flex-row flex-wrap gap-5 justify-center">
                    {dataSample?.team_member_tag4?.split("#")?.map((tag) => {
                      return tag?.length > 0 ? (
                        <div className="flex flex-row items-center">
                          <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                            #
                          </div>
                          <div
                            style={{ border: "1px solid #9CADC8" }}
                            className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                          >
                            {tag}
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                  <div
                    className="w-full h-0"
                    style={{ border: "1px solid #222" }}
                  />
                  <p className="text-[14px]">{dataSample?.team_member_4}</p>
                </div>
              </WhiteCard>
            </div>
            {/* Environment End */}

            {/* One Piece Advice Start */}
            <div className="flex flex-row justify-center gap-3 mt-20 mb-10">
              <div
                className="h-0 w-[80px] mt-5"
                style={{ border: "1px solid #222" }}
              />
              <div className="flex flex-col items-center">
                <p className="text-[14px] lg:text-[24px] font-bold text-center">
                  ワンポイントアドバイス
                </p>
                <p className="text-[14px] font-semibold">Key piece of advice</p>
              </div>
              <div
                className="h-0 w-[80px] mt-5"
                style={{ border: "1px solid #222" }}
              />
            </div>
            <div
              className="p-2 rounded-3xl"
              style={{
                background: "linear-gradient(90deg, #B69AC5 0%, #7FC4CA 100%)",
              }}
            >
              <WhiteCard customClass="p-5">
                <div className="mb-5">
                  <p className="text-[18px] text-center font-semibold">
                    一言キャリアアドバイス
                  </p>
                </div>
                <div className="flex flex-col xl:grid xl:grid-cols-4 items-start gap-1">
                  <div className="flex flex-col items-center gap-5 px-5">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.advice_tag1?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.advice_1}</p>
                  </div>

                  <div className="flex flex-col items-center gap-5 px-5">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.advice_tag2?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.advice_2}</p>
                  </div>

                  <div className="flex flex-col items-center gap-5 px-5">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.advice_tag3?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.advice_3}</p>
                  </div>

                  <div className="flex flex-col items-center gap-5 px-5">
                    <div className="flex flex-row flex-wrap gap-5 justify-center">
                      {dataSample?.advice_tag4?.split("#")?.map((tag) => {
                        return tag?.length > 0 ? (
                          <div className="flex flex-row items-center">
                            <div className="w-5 h-[36px] bg-[#9CADC8] flex items-center justify-center text-white">
                              #
                            </div>
                            <div
                              style={{ border: "1px solid #9CADC8" }}
                              className="h-[36px] flex items-center justify-start px-2 font-bold text-[16px] w-max"
                            >
                              {tag}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </div>
                    <div
                      className="w-full h-0"
                      style={{ border: "1px solid #222" }}
                    />
                    <p className="text-[14px]">{dataSample?.advice_4}</p>
                  </div>
                </div>
              </WhiteCard>
            </div>
            {/* One Piece Advice End */}
          </div>
        </div>
      </div>
    </>
  );
}
