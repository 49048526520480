import React, { useEffect, useState } from "react";
import { nativeBlack } from "../../assets";
import LoginBody from "./LoginBody";

const Login = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 500);
    return () => {
      setIsPageLoading(true);
      clearTimeout(timer);
    };
  }, []);

  return isPageLoading ? (
    <div className="h-[100svh] w-full flex-col flex items-center justify-center bg-white">
      <div className="flex flex-row items-end gap-2">
        <img src={nativeBlack} className="w-[100px]" alt="" />
        <p className="text-[16px] text-black font-semibold relative top-1">
          for Biz
        </p>
      </div>
    </div>
  ) : (
    <div className=" bg-white w-full min-h-[100svh]">
      <div
        className="flex flex-row items-center justify-center p-6"
        style={{
          borderBottom: "3px solid rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="flex flex-row items-end gap-2">
          <img src={nativeBlack} className="w-[100px]" alt="" />
          <p className="text-[16px] text-black font-semibold relative top-1">
            for Biz
          </p>
        </div>
      </div>
      <LoginBody />
    </div>
  );
};

export default Login;
